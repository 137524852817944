import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Transportation Planing Engineering`}</h1>
    <h2>{`Transportation planning and engineering books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=16mDHgTywA7Joiof5fy0DnSW7_gnyFalo"
      }}>{`Download: Transportation engineering and planning by C.S. Papacostas & P.D. Prevedouras`}</a></p>
    <h2>{`Transportation planning and engineering lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Lecture-1-Introduction.pptx"
      }}>{`Lecture # 1-Introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Lecture-2-Transportation-Problems-and-Models-in-Transportation.pptx"
      }}>{`Lecture # 2 Transportation Problems and Models in Transportation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Lecture-3-Transportation-demand-analysis.pptx"
      }}>{`Lecture # 3 Transportation demand analysis`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      